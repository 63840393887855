import React from "react"
import Link from "./Link"
import SearchInput from "./search/Input"
import menuLinks from "../menus/menuLinks"

const Menu = ({ pageTitle, onClickConnect, showConnect }) => (
  <div className="pt4 pt0-l absolute-l fixed-l left-2-l">
    <h2 className="ma0 f3 f2-l serif di">
      <Link className={"siteTitle no-underline"} to="/">
        {pageTitle}
      </Link>
    </h2>
    <nav className="list mt0 mt4-l f4 f5-l pl0 db main-nav">
      <ul className="serif ma0 mt1 mt2-ns pa0 dib db-ns">
        {menuLinks.map((menuItem, index) => {
          let noLeftPaddingOnFirstItem = index === 0 ? `` : `pl2`
          return (
            <li
              className={`pl0-l di db-l ${noLeftPaddingOnFirstItem}`}
              key={menuItem.text}
            >
              <Link
                className={"no-underline h2 dib lh-copy"}
                to={menuItem.to}
                key={menuItem.text}
              >
                {menuItem.text}
              </Link>
            </li>
          )
        })}
        <li className={`pl0-l di dn-l pl2`}>
          <button
            className={"no-underline h2 dib lh-copy text-btn pa0"}
            onClick={() => onClickConnect(!showConnect)}
          >
            connect
          </button>
        </li>
        <li className="pl2 pl0-l di db-l">
          <SearchInput />
        </li>
      </ul>
    </nav>
  </div>
)

export default Menu
