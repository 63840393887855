import React from "react"
import socialLinks from "../menus/socialLinks"

const SocialMedia = () => (
  <nav
    className="social dn db-l top-2 tr absolute fixed right-2"
    aria-label="Main"
  >
    <ul className="pl0 list serif">
      {socialLinks.map(social => (
        <li className="pt2" key={social.text}>
          <a
            href={social.link}
            rel="noopener noreferrer"
            target="_blank"
            className="almost-gray no-underline"
          >
            {social.text}
          </a>
        </li>
      ))}
    </ul>
  </nav>
)

export default SocialMedia
