import React, { useState } from "react"
import Link from "./Link"
import Connect from "./Connect"
import menuLinks from "../menus/menuLinks"

const Footer = () => {
  const [showConnect, setShowConnect] = useState(false)

  return (
    <footer className="mt6 mb4 tl-m tc center mw75-ns dn-l">
      <nav className="list mt0 mt4-l f5 f5-l pl0 db">
        <ul className="serif ma0 mt1 mt2-ns pa0 dib db-ns">
          {menuLinks.map((menuItem, index) => {
            return (
              <li key={index} className={`${index !== 0 && `pl3`} di`}>
                <Link className={"light-silver no-underline"} to={menuItem.to}>
                  {menuItem.text}
                </Link>
              </li>
            )
          })}
          <li className={`pl3 di`}>
            <button
              className={"no-underline h2 dib lh-copy text-btn pa0"}
              onClick={() => setShowConnect(!showConnect)}
            >
              connect
            </button>
          </li>
        </ul>
      </nav>
      {showConnect && <Connect />}
    </footer>
  )
}

export default Footer
