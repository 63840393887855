import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { InstantSearch } from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"
import { connectStateResults } from "react-instantsearch-dom"

import Header from "./Header"
import Footer from "./Footer"
import SearchResults from "./search/Results"

import "./layout.css"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          searchIndex
        }
      }
    }
  `)

  return (
    <InstantSearch
      indexName={data.site.siteMetadata.searchIndex}
      searchClient={searchClient}
    >
      <div className="page-content pa3 pa4-ns br1">
        <Header siteTitle={data.site.siteMetadata.title} />
        <main className="pv5 pt5 pt6-m pv7-l center mw75-ns">
          <BodyContent children={children} />
        </main>
        <Footer />
      </div>
    </InstantSearch>
  )
}

/**
 * If we are searching, show the search container, otherwise render
 * the children of the page.
 */
const BodyContent = connectStateResults(({ searchState, children }) =>
  searchState && searchState.query ? <SearchResults /> : <>{children}</>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
