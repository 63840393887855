import { connectSearchBox } from "react-instantsearch-dom"
import React from "react"

const SearchInput = ({ currentRefinement, isSearchStalled, refine }) => (
  <form noValidate action="" role="search" className="dib pa0 ma0">
    <input
      className="f4 f5-l bw0 dib w4 h2 lh-copy almost-gray "
      placeholder="search"
      type="search"
      enterkeyhint="search"
      aria-label="Search article contents"
      value={currentRefinement}
      onChange={event => refine(event.currentTarget.value)}
    />
  </form>
)

export default connectSearchBox(SearchInput)
