import React from "react"
import PropTypes from "prop-types"
import favicon from "../images/favicon.png"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  description,
  lang,
  meta,
  title,
  slug,
  isArticle,
  publishedAt,
  image,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            feedSlug
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const img = `${site.siteMetadata.siteUrl}/${image}`

  const structuredData = isArticle ? (
    <script type="application/ld+json">{`
      {
        "@context": "https://schema.org",
        "@type": "Article",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "${site.siteMetadata.siteUrl}/${slug}"
        },
        "headline": "${title}",
        "image": [
          "${img}"
        ],
        "datePublished": "${publishedAt}",
        "author": {
          "@type": "Person",
          "name": "${site.siteMetadata.author}"
        },
        "publisher": {
          "@type": "Person",
          "name": "${site.siteMetadata.author}"
        },
        "description": "${description}"
      }
    `}</script>
  ) : null

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      defaultTitle={site.siteMetadata.title}
      link={[
        { rel: `shortcut icon`, type: `image/png`, href: `${favicon}` },
        {
          rel: `alternate`,
          type: `application/rss+xml`,
          title: site.siteMetadata.title,
          href: `${site.siteMetadata.siteUrl}/${site.siteMetadata.feedSlug}`,
        },
        {
          rel: `canonical`,
          href: `${site.siteMetadata.siteUrl}/${slug}`,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: img,
        },
        {
          property: `og:image:type`,
          content: `image/png`,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `630`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:image`,
          content: img,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {structuredData}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  slug: ``,
  description: ``,
  publishedAt: ``,
  isArticle: false,
  image: `og-default.png`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  slug: PropTypes.string,
  publishedAt: PropTypes.string,
  isArticle: PropTypes.bool,
  image: PropTypes.string,
}

export default SEO
