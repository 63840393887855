import React from "react"
import { Link } from "gatsby"
import { connectHits, Highlight, Snippet } from "react-instantsearch-dom"

const ELLIPSIS = "…"
const Hits = connectHits(({ hits }) => {
  if (hits.length <= 0)
    return <p class="sans f6">Sorry, couldn’t find any matches :(</p>
  return hits.map(hit => (
    <article key={hit.objectID} className="ais-result mb5-l mb4">
      <h3 className="ais-result-header mt0 mb2 pt0-ns pt1 f3 f2-l measure serif">
        <Link to={`/${hit.slug}`} className="ais-result-link">
          <Highlight attribute="title" hit={hit} tagName="strong" />
        </Link>
      </h3>
      <Snippet
        attribute="excerpt"
        hit={hit}
        className="f45 lh-tight lh-copy-ns black-80 pb3"
      />
      {ELLIPSIS}
    </article>
  ))
})

export default Hits
