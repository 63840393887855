const colors = [
  {
    r: `68`,
    g: `119`,
    b: `170`,
  },
  {
    r: `102`,
    g: `204`,
    b: `238`,
  },
  {
    r: `34`,
    g: `136`,
    b: `51`,
  },
  {
    r: `204`,
    g: `187`,
    b: `68`,
  },
  {
    r: `238`,
    g: `102`,
    b: `119`,
  },
  {
    r: `170`,
    g: `51`,
    b: `119`,
  },
]

module.exports = colors
