const menuLinks = [
  {
    to: `/`,
    text: "index",
  },
  {
    to: `/about`,
    text: "about",
  },
]

export default menuLinks
