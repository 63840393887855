import React, { useState } from "react"
import PropTypes from "prop-types"
import Menu from "./Menu"
import SocialMedia from "./SocialMedia"
import Connect from "./Connect"

const Header = ({ siteTitle }) => {
  const [showConnect, setShowConnect] = useState(false)

  return (
    <header className="mw75-ns center">
      <Menu
        pageTitle={siteTitle}
        showConnect={showConnect}
        onClickConnect={setShowConnect}
      />
      {showConnect && <Connect />}
      <SocialMedia />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
