import React from "react"
import socialLinks from "../menus/socialLinks"

const Connect = () => (
  <nav className="socialMobile dn-l">
    <ul className="pl0 ma0 p0 list serif connectIcons">
      {socialLinks.map(social => (
        <li className="pt2 db" key={social.text}>
          <a
            href={social.link}
            rel="noopener noreferrer"
            target="_blank"
            className="almost-gray no-underline"
          >
            {social.text}
          </a>
        </li>
      ))}
    </ul>
  </nav>
)

export default Connect
