const colors = require(`./colorData`)

export const applyTheme = (color = colors[1]) => {
  const { r, g, b } = color
  const root = document ? document.documentElement : {}

  const theme = {
    "--theme-color": `rgba(${r},${g},${b},1)`,
    "--highlight-color": `rgba(${r},${g},${b},1)`,
    "--highlight-color-transparent": `rgba(${r},${g},${b},0.05)`,
    "--page-wash-color": `rgba(${r},${g},${b},0.025)`,
  }

  Object.keys(theme).map(key => {
    const value = theme[key]
    root.style.setProperty(key, value)
    return null
  })
}
