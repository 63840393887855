const socialLinks = [
  {
    id: 'fb',
    link: `//fb.com/boz`,
    text: `fb.com/boz`,
  },
  {
    id: 'twitter',
    link: `//twitter.com/boztank`,
    text: `twitter/@boztank`,
  },
  {
    id: 'instagram',
    link: `//instagram.com/boztank`,
    text: `instagram/@boztank`,
  },
  {
    id: 'mail',
    link: `mailto:webmaster@boz.com`,
    text: `mailto:boz`,
  },
]

export default socialLinks
